import { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "@emotion/react";
import { Backdrop, Box, Button, Chip, Fade, Grid, Modal, TextField, Typography } from "@mui/material";
import swal from "sweetalert2";
import { VerifyTag } from "../../../../../hooks/verifyTag";
import { Delete } from "@mui/icons-material";
import { Spliteo } from "../../../../../hooks/spliteo";
import { TiposSplitEnum } from "../../../../../@enums/tipo_split.enum";
import { useSelector } from "react-redux";
import { useLoading } from "../../../../../components/Loading/useLoading";
import { TicketLocal } from "../../../../../hooks/sendPrint";

export const ModalSplit = ({open, setOpen, almacen}) => {

  const style = {
    position: "absolute",
    top: "35%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 500,
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    display: "flex",
    justifyContent: "center",
  };
  const {setAppLoading} = useLoading();
  const formData = useRef(null);
  const inputRefSplit = useRef(null);
  const printerId = useSelector((state) => state.app.printerId);
  const theme = useContext(ThemeContext);
  const {verifyTagMutation} = VerifyTag();
  const {spliteoMutation} = Spliteo();
  const { ticketLocalMutation } = TicketLocal();
  const [paquetes, setPaquetes] = useState([]);
  const [isMaster, setIsMaster] = useState(false);

  useEffect(() => {
    if (open) {
        setTimeout(() => {
            if (inputRefSplit.current) {
              inputRefSplit.current.focus();
            }
        }, 200); // Esperar un corto tiempo para asegurarse de que el modal esté completamente renderizado
    }
  }, [open]);

  const handleClose = () => {
    setPaquetes([]);
    setOpen(false);
    setIsMaster(false);
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setAppLoading(true)
      if (paquetes.some((item) => item?.tag === event.target.value)) {
        setAppLoading(false)
        swal.fire({
          position: "center",
          icon: "warning",
          title: "El contenedor ya ha sido agregado",
          width: 600,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            container: "cont_alt",
          },
        });
      } else {
        let body = {
          etiq_codigo: event.target.value
        };
        verifyTagMutation({almacen: almacen, data: body}, {
          onSuccess: (data) => {
            setAppLoading(false)
            if (data.success !== false && data.data?.length > 0) {
              if(paquetes.length > 0 && paquetes[0].parte !== data.data[0].parte){
                swal.fire({
                  position: "center",
                  icon: "error",
                  title:
                    "El contenedor no existe o no cumple con las condiciones necesarias",
                  width: 600,
                  showConfirmButton: false,
                  timer: 1500,
                  customClass: {
                    container: "cont_alt",
                  },
                });
              }else{
                setPaquetes([...paquetes, ...data.data]);
                if(data.data.some(obj => obj.master === true)){
                  setIsMaster(true)
                }
                formData.current.etiqueta.value = "";
              }
            } else {
              swal.fire({
                position: "center",
                icon: "error",
                title:
                  "El contenedor no existe o no cumple con las condiciones necesarias",
                width: 600,
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                  container: "cont_alt",
                },
              });
            }
          },
          onError: (error) => {
            console.log(error);
            setAppLoading(false)
            swal.fire({
              position: "center",
              icon: "error",
              title:
                "El contenedor no existe o no cumple con las condiciones necesarias",
              width: 600,
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                container: "cont_alt",
              },
            });
          },
        });
      }
      inputRefSplit.current.focus();
    }
  };

  const deleteItem = (index) => {
    let paq = paquetes;
    if(isMaster){
      paq[index].delete = true;
    }else{
      paq.splice(index, 1);
    }
    
    setPaquetes([...paq]);
  }

  const do_split = (params) => {
    spliteoMutation(params, {
      onSuccess: (data) => {
        for(let print of data?.data){
          ticketLocalMutation(print, {
              onSuccess: (data) => {
                  console.log(data)
              },
              onError: (error) => {
                  console.log(error)
              }
          })
        }
        handleClose();
        setAppLoading(false);
      },
      onError: (error) => {
        console.log(error);
        handleClose();
        setAppLoading(false);
      }
    })
  }

  const send_split = () => {
    setAppLoading(true);
    if(paquetes.length > 0){
      let params = {
        tipo: isMaster ? 
              TiposSplitEnum.EDICION : TiposSplitEnum.CREACION,
        data:  {
          data: paquetes,
          printerId: printerId
        }
      }
      do_split(params)
    }else{
      setAppLoading(false);
    }
  }

  const deshacer_split = () => {
    swal.fire({
      title: "¿Esta seguro de deshacer esta master?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Deshacer",
      customClass: {
        container: "cont_alt",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setAppLoading(true);
        let paq = paquetes;
        paq.map(objeto => objeto.delete = true);
        let params = {
          tipo: TiposSplitEnum.EDICION,
          data:  {
            data: paq,
            printerId: printerId
          }
        }
        do_split(params)
      }
    });
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style} className="modal_carga">
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sx={{ p: 0, mb: 1, pr: 1 }}
              className="center_item"
            >
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Contenedores Masters
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              xl={4}
              sx={{
                p: 1,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
                flexDirection: "column",
              }}
            >
              <form ref={formData} id="form_dom" style={{ width: "100%" }}>
                <TextField
                  id="etiqueta"
                  size="small"
                  label="Etiqueta"
                  fullWidth
                  inputRef={inputRefSplit}
                  onKeyDown={handleEnterPress}
                  sx={{ color: theme.palette.primary.input, width: "100%" }}
                />
                <Button
                variant="contained"
                className="btn_system"
                sx={{ fontSize: 12, height: 35, mt:2.5 }}
                disableElevation
                disabled={paquetes.length === 0}
                onClick={send_split}
                >
                  Contenedor Lleno
                </Button>
                {
                  isMaster && 
                  <Button
                  variant="contained"
                  className="btn_system"
                  sx={{ fontSize: 12, height: 35, mt:2.5 }}
                  disableElevation
                  disabled={paquetes.length === 0}
                  onClick={deshacer_split}
                  >
                    Deshacer master
                  </Button>
                }
              </form>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              sx={{
                p: 1,
                gap: 2,
                display: "flex",
                alignItems: "flex-start",
                border: "1px solid #D9D9D9",
                borderRadius: 5,
              }}
            >
              <Grid
                container
                spacing={0}
                sx={{
                  p: 1,
                  textAlign: "start",
                  display: "flex",
                  alignItems: "center",
                  overflowY: 'scroll',
                  maxHeight: '400px'
                }}
              >
                {paquetes?.map((paq, index) => (
                  <>
                  {
                    !paq?.delete &&
                    <Grid
                    item
                    xs={6}
                    sx={{
                      p: 1,
                      textAlign: "start",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    >
                      <Chip
                        label={
                          <div
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                marginRight: 7,
                                fontSize: 12,
                                fontWeight: 400,
                                color: "black",
                              }}
                            >
                              {paq?.cantidad}
                            </span>
                            <span
                              style={{
                                marginLeft: 7,
                                border: "1px solid #575E3B",
                                borderRadius: 4,
                                fontSize: 11,
                                padding: 3,
                                color: "#575E3B",
                              }}
                            >
                              {paq?.tag}
                            </span>
                          </div>
                        }
                        variant="outlined"
                        deleteIcon={
                          <Delete sx={{ marginLeft: "5px !important", width:18 }} />
                        }
                        onDelete={()=>deleteItem(index)}
                        sx={{
                          height: 40,
                          borderRadius: 2,
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          backgroundColor: "transparent",
                        }}
                      />
                    </Grid>
                  }
                  </>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  )
}