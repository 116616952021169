import axios from "axios";
import { useQuery } from "react-query";

export const usePrinterId = ({ ...options }) => {
    let localApiHost = "http://127.0.0.1:11000";
    return useQuery(
        ["printerId"],
        async () => {
            try {
                const response = await axios.get(`https://127.0.0.1:11000/printer-id`);
                if (response.status !== 200) {
                    return null;
                }
                return response.data;
            } catch (error) {   
                return null;
            }
        },
        options
    );
};
