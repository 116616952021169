import { Routes, Route, useLocation } from "react-router-dom";
import {
  AccountTree,
  Business,
  Category,
  MoveToInbox,
  Unarchive,
  Warehouse,
  AirplaneTicket,
  UndoOutlined,
} from "@mui/icons-material";
import styled from "@emotion/styled";
import { Box, CssBaseline } from "@mui/material";
import { ProtectedRoutes } from "./private_routes";
import AppBarComponent from "../components/appbar";
import IndexProducts from "../views/inventario/productos";
import Productos from "../views/inventario/productos/productos";
import EntradasIndex from "../views/inventario/entradas/entradas";
import PanelDeControlIndex from "../views/inventario/panel_control/panel_control";
import IndexAlmacenes from "../views/inventario/almacenes";
import DataAlmacen from "../views/inventario/almacenes/dataAlmacen";
import IndexClientes from "../views/inventario/clientes";
import Empresa from "../views/inventario/clientes/empresa";
import Contacto from "../views/inventario/contactos/contacto";
import NuevoCliente from "../views/inventario/clientes/nuevoCliente";
import NuevaEntrada from "../views/inventario/entradas/nuevaEntrada";
import Entrada from "../views/inventario/entradas/entrada";
import {
  EtiquetasIndex,
  EtiquetasNuevoFormato,
  EtiquetasEditarFormato,
} from "../views/inventario/etiquetas";
import { EtiquetasNuevoItem } from "../views/inventario/etiquetas/EtiquetasNuevoItem";
import { EtiquetasEditarItem } from "../views/inventario/etiquetas/EtiquetasEditarItem";
import { SalidasIndex } from "../views/inventario/salidas/salidasIndex";
import { AccessRoutes } from "./access_route";
import { useDispatch } from "react-redux";
import swal from "sweetalert2";
import { AUTH_LOGOUT } from "../redux/reducers/auth.reducer";
import InactivityTimer from "../components/timerActivity";
import { DevoluvionesIndex } from "../views/inventario/devoluciones/devolucionesIndex";
import { NuevaDevolucion } from "../views/inventario/devoluciones/nuevaDevolucion";
import { Devolucion } from "../views/inventario/devoluciones/devolucion";
const InventarioRoutes = () => {
  const menu_options = [
    {
      id: 1,
      name: "Productos",
      icon: <Category />,
      redirect: "/inventarios/partes",
    },
    {
      id: 2,
      name: "Entradas",
      icon: <MoveToInbox />,
      redirect: "/inventarios/entradas",
    },
    {
        id: 3,
        name: 'Devoluciones',
        icon: (<UndoOutlined/>),
        redirect: '/inventarios/devoluciones'
    },
    {
      id: 4,
      name: "Salidas",
      icon: <Unarchive />,
      redirect: "/inventarios/salidas",
    },
    {
      id: 5,
      name: "Panel de Control",
      icon: <AccountTree />,
      redirect: "/inventarios/panel",
    },
    {
      id: 6,
      name: "Almacenes",
      icon: <Warehouse />,
      redirect: "/inventarios/almacenes",
    },
    {
      id: 7,
      name: "Clientes",
      icon: <Business />,
      redirect: "/inventarios/clientes",
    },
    {
      id: 8,
      name: "Etiquetas",
      icon: <AirplaneTicket />,
      redirect: "/inventarios/etiquetas",
    },
  ];

  const location = useLocation();
  let path = location?.pathname.split("/").filter((item) => item !== "");

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const dispatch = useDispatch();

  const onInactive = () => {
    swal
    .fire({
      position: "center",
      icon: "warning",
      title: "Tiempo de sesión expirado por inactividad",
      showConfirmButton: false,
      timer: 10000,
    })
    .then(() => {
      dispatch(
        AUTH_LOGOUT()
      );
    });
  }

  return (
    <>
      {path[0] === "inventarios" && (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBarComponent
            key={"app_bar_inventario"}
            menuOptions={menu_options}
          />
          <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
            <DrawerHeader />
            <Routes>
              <Route element={<ProtectedRoutes />}>
                <Route
                  key="products_products"
                  path="/inventarios/partes"
                  element={
                    <AccessRoutes
                      key={"access_products"}
                      permission={"MRP:Inventario:Partes:*"}
                    >
                      <IndexProducts />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="products_general"
                  path={`/inventarios/partes/:id`}
                  element={
                    <AccessRoutes
                      key={"access_product_id"}
                      permission={"MRP:Inventario:Partes:Detalle"}
                    >
                      <Productos />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="entradas"
                  path={`/inventarios/entradas`}
                  element={
                    <AccessRoutes
                      key={"access_entrada"}
                      permission={"MRP:Inventario:Entradas:*"}
                    >
                      <EntradasIndex />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="entrada_nueva"
                  path={`/inventarios/entradas/agregar`}
                  element={
                    <AccessRoutes
                      key={"access_entrada"}
                      permission={"MRP:Inventario:Entradas:Agregar"}
                    >
                      <NuevaEntrada />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="entrada_data"
                  path={`/inventarios/entradas/:id`}
                  element={
                    <AccessRoutes
                      key={"aceess_entrada_detalle"}
                      permission={"MRP:Inventario:Entradas:Detalle"}
                    >
                      <Entrada />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="panel"
                  path={`/inventarios/panel`}
                  element={
                    <AccessRoutes
                      key={"access_panel"}
                      permission={"MRP:Inventario:Panel:*"}
                    >
                      <PanelDeControlIndex />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="almacenes"
                  path={`/inventarios/almacenes`}
                  element={
                    <AccessRoutes
                      key={"access_wharehouses"}
                      permission={"MRP:Inventario:Almacenes:*"}
                    >
                      <IndexAlmacenes />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="almacenes_data"
                  path={`/inventarios/almacenes/:id`}
                  element={
                    <AccessRoutes
                      key={"access_wharehouse_det"}
                      permission={"MRP:Inventario:Almacenes:Detalle"}
                    >
                      <DataAlmacen />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="clientes"
                  path={`/inventarios/clientes`}
                  element={
                    <AccessRoutes
                      key={"access_client"}
                      permission={"MRP:Inventario:Clientes:*"}
                    >
                      <IndexClientes />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="cliente_nuevo"
                  path={`/inventarios/clientes/nuevo`}
                  element={
                    <AccessRoutes
                      key={"access_client_new"}
                      permission={"MRP:Inventario:Clientes:Agregar"}
                    >
                      <NuevoCliente />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="cliente_data"
                  path={`/inventarios/clientes/:id`}
                  element={
                    <AccessRoutes
                      key={"access_client_det"}
                      permission={"MRP:Inventario:Clientes:Detalle"}
                    >
                      <Empresa />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="contacto_data"
                  path={`/inventarios/contactos/:id`}
                  element={
                    <AccessRoutes
                      key={"access_contact"}
                      permission={"MRP:Inventario:Contactos:Detalle"}
                    >
                      <Contacto />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="etiquetas"
                  path={`/inventarios/etiquetas`}
                  element={
                    <AccessRoutes
                      key={"access_tags"}
                      permission={"MRP:Inventario:Etiquetas:*"}
                    >
                      <EtiquetasIndex />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="etiquetas-nuevo"
                  path={`/inventarios/etiquetas/nuevo`}
                  element={
                    <AccessRoutes
                      key={"access_tag_new"}
                      permission={"MRP:Inventario:Etiquetas:Agregar"}
                    >
                      <EtiquetasNuevoFormato />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="etiquetas-editar"
                  path={`/inventarios/etiquetas/:id`}
                  element={
                    <AccessRoutes
                      key={"access_tag_edit"}
                      permission={"MRP:Inventario:Etiquetas:Editar"}
                    >
                      <EtiquetasEditarFormato />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="etiquetas-item-nuevo"
                  path={`/inventarios/etiquetas/items/:id`}
                  element={
                    <AccessRoutes
                      key={"access_tag_item_new"}
                      permission={"MRP:Inventario:Etiquetas:Items:Agregar"}
                    >
                      <EtiquetasNuevoItem />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="etiquetas-item-editar"
                  path={`/inventarios/etiquetas/items/editar/:id`}
                  element={
                    <AccessRoutes
                      key={"access_tag_item_edit"}
                      permission={"MRP:Inventario:Etiquetas:Items:Editar"}
                    >
                      <EtiquetasEditarItem />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="salidas"
                  path={`/inventarios/salidas`}
                  element={
                    <AccessRoutes
                      key={"access_routes_salidas"}
                      permission={"MRP:Inventario:Salidas:*"}
                    >
                      <SalidasIndex />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="devoluciones"
                  path={`/inventarios/devoluciones`}
                  element={
                    <AccessRoutes
                      key={"access_routes_salidas"}
                      permission={"MRP:Inventario:Devoluciones:*"}
                    >
                      <DevoluvionesIndex />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="devoluciones_nueva"
                  path={`/inventarios/devoluciones/nueva`}
                  element={
                    <AccessRoutes
                      key={"access_routes_salidas"}
                      permission={"MRP:Inventario:Devoluciones:Agregar"}
                    >
                      <NuevaDevolucion />
                    </AccessRoutes>
                  }
                />
                <Route
                  key="devolucion"
                  path={`/inventarios/devoluciones/:id`}
                  element={
                    <AccessRoutes
                      key={"access_routes_salidas"}
                      permission={"MRP:Inventario:Devoluciones:Detalle"}
                    >
                      <Devolucion />
                    </AccessRoutes>
                  }
                />
              </Route>
            </Routes>
            <InactivityTimer key='inactivity_component' onInactive={onInactive}/>
          </Box>
        </Box>
      )}
    </>
  );
};

export default InventarioRoutes;
