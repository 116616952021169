import { ThemeContext } from "@emotion/react";
import { Backdrop, Box, Button, Fade, Grid, Modal, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { ReetiquetarSalida } from "../../../../hooks/reetiquetado";
import { TicketLocal } from "../../../../hooks/sendPrint";
import { useLoading } from "../../../../components/Loading/useLoading";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 500,
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  display: "flex",
  justifyContent: "center",
};

export const ModalReimpresion = ({open, setOpen, orden}) => {
  const theme = useContext(ThemeContext);
  const { enqueueSnackbar } = useSnackbar();
  const {reetiquetarSalidaMutation} = ReetiquetarSalida();
  const { ticketLocalMutation } = TicketLocal();
  const printerId = useSelector((state) => state.app.printerId);
  const {setAppLoading} = useLoading();
  const [facturaFolio, setFacturaFolio] = useState('');
  const [shipperInfoAdicional, setShipperInfoAdicional] = useState('');

  const onClose = () => {
    setOpen(false);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setAppLoading(true);
    let body = {
      shipper_folio: `${orden.docu_folio}`,
      factura_folio: facturaFolio,
      shipper_info_adicional: shipperInfoAdicional,
      printerId
    }
    reetiquetarSalidaMutation(body, {
      onSuccess: (data) => {
        for(let print of data){
          ticketLocalMutation(print, {
              onSuccess: (data) => {
                  console.log(data)
              },
              onError: (error) => {
                  console.log(error)
              }
          })
      }
        enqueueSnackbar(
          'Reetiquetado completado', 
          {
            variant: 'success', 
            preventDuplicate: true, 
            autoHideDuration: 2000, 
            anchorOrigin: 
            { 
              vertical: 'top', 
              horizontal: 'center'
            }
          }
        );
        onClose()
        setAppLoading(false);
      },
      onError: (error) => {
        console.log(error);
        enqueueSnackbar(
          'Error de reetiquetado', 
          {
            variant: 'error', 
            preventDuplicate: true, 
            autoHideDuration: 2000, 
            anchorOrigin: 
            { 
              vertical: 'top', 
              horizontal: 'center'
            }
          }
        );
        onClose()
        setAppLoading(false);
      }
    })
  };

  return (
    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={onClose}
    closeAfterTransition
    slots={{ backdrop: Backdrop }}
    slotProps={{
      backdrop: {
        timeout: 500,
      },
    }}
    >
      <Fade in={open}>
        <Box sx={style} className="modal_carga">
          <Grid container spacing={0} sx={{width: '100%'}}>
            <Grid
              item
              xs={12}
              sx={{ p: 0, mb: 1, pr: 1 }}
              className="center_item"
            >
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Reetiquetado
              </Typography>
            </Grid>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                '& .MuiTextField-root': { mb: 2 },
                maxWidth: 400,
                mx: 'auto',
                mt: 2,
                p: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="factura_folio"
                    label="Folio de Factura"
                    fullWidth
                    size="small"
                    value={facturaFolio}
                    sx={{ color: theme.palette.primary.input }}
                    onChange={(e) => setFacturaFolio(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="shipper_info_adicional"
                    label="Información Adicional"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={shipperInfoAdicional}
                    sx={{ color: theme.palette.primary.input }}
                    onChange={(e) => setShipperInfoAdicional(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  )
}