import { Search } from "@mui/icons-material";
import { Grid, InputAdornment, Paper, TextField } from "@mui/material";
import moment from "moment";
import { GroupingTable } from "../../../components/Table/grouping_table";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "@emotion/react";

export const TablaDevoluciones = ({sample, filtro, handleInputChange, onPressEnter}) => {
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const sort = {}

  const rows = sample;
  const columns = [
    {
      accessorKey: 'docu_folio',
      header: 'Folio',
    }, 
    {
      accessorFn: (row) => moment(row?.docu_fecha, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY") || '',
      id: 'fecha',
      header: 'Fecha',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      accessorKey: 'docu_serie',
      header: 'Serie',
    },
    {
      accessorFn: (row) => row.empresas?.emp_razonsocial || '',
      id: 'cliente',
      header: 'Cliente',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
  ];

  const redirect = (row) => {
    if(row){
      navigate(`/inventarios/devoluciones/${row?.docu_id}`);
    }
  }

  return(
      <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
        <Grid container spacing={0}>
          <Grid item xs={12} sx={{p:1, justifyContent: 'flex-end'}} className="center_item">
              <TextField
              id="search_entry"
              label="Ingrese palabra clave"
              name="search_entry"
              size="small"
              value={filtro}
              onChange={handleInputChange}
              onKeyDown={onPressEnter}
              InputProps={{
                  startAdornment: (
                  <InputAdornment position="start">
                      <Search sx={{fontSize:20}}/>
                  </InputAdornment>
                  ),
              }}
              sx={{ color: theme.palette.primary.input }}
              />
          </Grid>
        </Grid>
        <GroupingTable  
        key='tabla_devoluciones' 
        columnas={columns} 
        data={rows} 
        redirect={redirect}
        sort={sort}
        />
      </Paper>
  )
}