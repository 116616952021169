import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const CreateCausaDevolicion = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.post(`/causas-devoluciones/crear-causa`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        crearCausaDevolucionMutation: mutation.mutate,
    };
}

export const GetCausasDevolucionesByTipo = ()  => {
    const api = useApi();

    const mutate = async (tipo) => {
        const data  = await api.get(`/causas-devoluciones/lista/${tipo}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getCausasDevolucionesByTipoMutation: mutation.mutate,
    };
}